<script>
import MarketingBlock from "@/components/rc-house/MarketingBlock.vue";
import Comparison from "@/components/rc-house/Comparison.vue";
import EntranceFee from "@/components/rc-house/EntranceFee.vue";
import ConditionsOfEntry from "@/components/rc-house/ConditionsOfEntry.vue";
import Calculator from "@/components/rc-house/Calculator.vue";
import Process from "@/components/rc-house/Process.vue";

export default {
  name: "RcHouse",
  components: {
    Process,
    Calculator,
    ConditionsOfEntry,
    EntranceFee,
    Comparison,
    MarketingBlock,
  },
};
</script>

<template>
  <section class="rc-house">
    <MarketingBlock />

    <Comparison />

    <EntranceFee v-if="false" />

    <ConditionsOfEntry />

    <Calculator v-if="false" />

    <Process />
  </section>
</template>

<style lang="scss">
.main-layout__name {
  background: $light-primary;
}

@media (min-width: 1024px) {
  .main-layout__name {
    background: transparent;
  }
}
</style>

<style lang="scss" scoped>
.rc-house {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (min-width: 576px) {
  .rc-house {
    gap: 12px;
  }
}

@media (min-width: 1024px) {
  .rc-house {
    gap: 32px;
    padding: 0 48px 48px 48px;
  }
}
</style>
