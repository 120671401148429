<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "EntranceFee",
  components: { MainButton },

  computed: {
    days() {
      return "30 дн.";
    },

    icon() {
      return require("../../assets/icons/circle-progress-bar/30days.svg");
    },
  },

  methods: {
    toRcHouse() {
      window.open(`${process.env.VUE_APP_RC_HOUSE_URL}`);
    },
  },
};
</script>

<template>
  <section class="entrance-fee">
    <div class="entrance-fee__progress progress">
      <img class="progress__icon" :src="icon" alt="days" />
      <p class="progress__text">{{ days }}</p>
    </div>

    <div class="entrance-fee__info">
      <h3>Скидка на вступительный взнос: 20% или 20 000 RC</h3>
      <p>
        Вступить в ЖНК теперь можно на тарифе <b>«Бизнес»</b>. Зарегистрируйтесь
        на сайте с 12 по 17 апреля
      </p>
    </div>

    <MainButton
      title="Перейти на сайт RC HOUSE"
      color="gold"
      padding="12px 24px"
      @click="toRcHouse"
    />
  </section>
</template>

<style lang="scss" scoped>
.entrance-fee {
  display: grid;
  grid-column: auto;
  width: 100%;
  padding: 16px;
  gap: 24px;
  border-radius: 20px;
  background: $services-online;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h3 {
    @include title-3;
    color: $dark-primary;
  }

  p {
    @include body-1;
    color: $dark-third;
  }

  .main-button {
    width: 100%;
  }
}

.progress {
  position: relative;
  height: 72px;
  width: 72px;

  &__icon {
    position: relative;
    z-index: 1;
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    @include text-2-bold;
    font-weight: 600 !important;
    color: $dark-primary;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .entrance-fee {
    padding: 32px 48px;
    grid-template-columns: 72px 1fr;
    grid-template-rows: auto auto;

    &__progress {
      grid-column: 1/1;
      grid-row: 1/1;
    }

    &__info {
      grid-column: 2/2;
      grid-row: 1/1;
    }

    .main-button {
      grid-column: 2/2;
      grid-row: 2/2;
      width: fit-content !important;
    }
  }
}

@media (min-width: 1024px) {
  .entrance-fee {
    padding: 32px;
  }
}

@media (min-width: 1440px) {
  .entrance-fee {
    padding: 48px;
    max-width: 820px;
  }
}
</style>
