<script>
export default {
  name: "Comparison",
};
</script>

<template>
  <section class="comparison">
    <div class="comparison__main-info">
      <h3>Сравнение предложений банков и ЖНК</h3>

      <p>
        Мы взяли за основу среднюю процентную ставку в разных банках и
        рассчитали конечную стоимость квартиры в сравнении с условиями ЖНК.
      </p>

      <ul>
        <p>Расчет конечной стоимости производился из следующих условий:</p>
        <li>Стоимость квартиры — 5 000 000 ₽</li>
        <li>Срок выплаты — 10 лет</li>
      </ul>
    </div>

    <table>
      <tr>
        <th>Условия</th>
        <th>RC House</th>
        <th>Банк</th>
      </tr>

      <tr>
        <td>
          <span class="icon-percent" />
          <p>Ставка</p>
        </td>
        <td>0%</td>
        <td>8,41%</td>
      </tr>

      <tr>
        <td>
          <span class="icon-money" />
          <p>Итого</p>
        </td>

        <td>5 430 000 ₽</td>
        <td>9 205 000 ₽</td>
      </tr>

      <tr>
        <td>
          <span class="icon-coin-1-bold" />
          <p>Переплата</p>
        </td>
        <td>430 000 ₽</td>
        <td>4 205 000 ₽</td>
      </tr>
    </table>
  </section>
</template>

<style lang="scss" scoped>
.comparison {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  background: $light-primary;
  border-radius: 20px;

  &__main-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: $dark-primary;

    h3 {
      @include title-3;
    }

    p {
      @include body-1;
      color: $dark-third;
    }

    ul {
      margin: 0;
      padding: 0;
      @include body-1;
      list-style: none;
      color: $dark-third;

      > p {
        margin-bottom: 8px;
      }
    }

    li:not(:last-of-type) {
      margin-bottom: 6px;
    }

    li:before {
      content: "•";
      padding-right: 6px;
      font-size: 14px;
      color: $dark-primary;
      font-weight: bold;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid rgba(33, 33, 33, 0.1);

      th {
        padding: 0 0 16px 0;
        @include body-1-bold;
        text-align: left;
      }

      td {
        padding: 16px 0;
        @include body-1;
        text-align: left;

        &:first-of-type {
          display: flex;
          align-items: center;
          text-align: left;
          gap: 8px;
        }

        span {
          min-width: 16px;
          height: 16px;
          background: $dark-fifth;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

@media (min-width: 576px) {
  .comparison {
    padding: 32px 48px;
  }

  table th {
    padding: 0 0 24px 0;
  }

  table td {
    padding: 24px 0;
  }
}

@media (min-width: 1024px) {
  .comparison {
    padding: 32px;

    &__main-info {
      gap: 24px;

      ul p {
        margin-bottom: 12px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .comparison {
    max-width: 820px;
    padding: 48px;
  }
}
</style>
