<script>
export default {
  name: "Process",

  data() {
    return {
      link: "",
      conditions: [
        {
          id: 1,
          title: "Заявка онлайн",
          text: "Не нужно посещать офис <br> и ждать в очередях",
          icon: "icon-bolt-fill ",
        },
        {
          id: 2,
          title: "Сопровождение ",
          text: "Поддержка менеджера <br> на всех этапах сделки",
          icon: "icon-group",
        },
        {
          id: 3,
          title: "Срок от 2 до 12,5 лет",
          text: "Выбирайте комфортный <br> срок и платеж",
          icon: "icon-schedule-fill",
        },
      ],

      steps: [
        {
          id: 1,
          title: "Подайте заявку",
          text:
            "В личном кабинете отправьте заявку \n" +
            "с указанием желаемой стоимость квартиры \n" +
            "и количества лет выплат",
        },
        {
          id: 2,
          title: "Подпишите документы",
          text:
            "Заполните и подпишите документы, \n" +
            "в которых будут прописаны все условия",
        },
        {
          id: 3,
          title: "Накопите первоначальный взнос",
          text:
            "До 50% от стоимости жилья, следите за очередью на приобретение квартиры",
        },
        {
          id: 4,
          title: "Приобретите квартиру",
          text: "Получите ключи от квартиры и начинайте обустраиваться",
        },
        {
          id: 5,
          title: "Погасите долг",
          text: "Оплатите оставшуюся часть стоимости квартиры",
        },
      ],
    };
  },

  mounted() {
    this.link = `${process.env.VUE_APP_RC_HOUSE_URL}`;
  },
};
</script>

<template>
  <section class="process">
    <section class="process__base-wrapper conditions">
      <h3>Прозрачные условия покупки квартиры</h3>

      <div class="conditions__list">
        <div
          v-for="condition in conditions"
          :key="condition.id"
          class="conditions__card card"
        >
          <div class="card__icon">
            <span :class="condition.icon"></span>
          </div>

          <h5>{{ condition.title }}</h5>

          <p v-html="condition.text" />
        </div>
      </div>
    </section>

    <section class="process__base-wrapper steps">
      <h3>Как купить квартиру</h3>

      <div class="steps__list">
        <div v-for="step in steps" :key="step.id" class="steps__step step">
          <div class="step__counter">{{ step.id }}</div>
          <span class="step__decorator" />
          <div class="step__info">
            <h5>{{ step.title }}</h5>
            <p>{{ step.text }}</p>
          </div>
        </div>
      </div>
    </section>

    <a class="link-like-button" :href="link" target="_blank">
      Перейти на сайт RC HOUSE
    </a>
  </section>
</template>

<style lang="scss" scoped>
.process {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 20px;
  background: $light-primary;
  width: 100%;

  &__base-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;

    h3 {
      @include title-3;
      color: $dark-primary;
    }

    h5 {
      @include body-1-bold;
      color: $dark-primary;
    }

    p {
      @include text-2;
      color: $dark-third;
    }
  }
}

.conditions {
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .card {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto auto;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 16px;
    border-radius: 12px;
    background: $light-second;
    height: 90px;
    column-gap: 16px;

    &__icon {
      width: 40px;
      height: 40px;
      background: $light-primary;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-row: -1/1;
      align-self: center;

      span {
        min-width: 20px;
        height: 20px;
        background: $dark-primary;
      }
    }
  }
}

.steps {
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .step {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-template-rows: 32px auto;
    column-gap: 16px;
    row-gap: 16px;

    &__counter {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 0 4px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: $light-third;
      @include text-2-bold;
      color: $dark-primary;
      line-height: 1;
    }

    &__decorator {
      display: block;
      width: 1px;
      height: auto;
      background: $light-second;
      grid-row: 2/2;
      grid-column: 1/1;
      justify-self: center;
    }

    &__info {
      grid-column: 2/2;
      grid-row: -1/1;
      padding-bottom: 24px;
    }

    h5 {
      margin-bottom: 4px;
    }

    &:nth-child(5) {
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 1023px) {
  .main-button {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .process {
    padding: 32px 48px;
  }
}

@media (min-width: 1024px) {
  .process {
    padding: 32px;
    gap: 48px;
  }

  .conditions {
    &__list {
      flex-direction: row;
      max-width: 100%;
      overflow: auto;
      gap: 24px;
    }

    .card {
      padding: 24px;
      column-gap: 24px;
      min-width: 330px;
      height: fit-content;
      grid-template-columns: 48px 1fr;

      &__icon {
        width: 48px;
        height: 48px;

        span {
          min-width: 24px;
          height: 24px;
        }
      }
    }
  }

  .step__info {
    padding-bottom: 32px;
  }

  .link-like-button {
    max-width: fit-content;
  }
}

@media (min-width: 1440px) {
  .process {
    max-width: 820px;
    padding: 48px;
  }
}
</style>
