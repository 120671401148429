<script>
import MainButton from "@/components/helpers/MainButton.vue";
import { mapState } from "vuex";

export default {
  name: "ConditionsOfEntry",
  components: { MainButton },

  data() {
    return {
      tariffs: [
        {
          name: "Франшиза",
          icon: "icon-vip_d",
        },
        {
          name: "Франшиза Голд",
          icon: "icon-vip_gold_d",
        },
      ],

      requiredTariffs: ["vip_d", "vip_gold_d"],
    };
  },

  computed: {
    ...mapState({
      tariff: (state) => state.auth.user.tariff,
    }),

    accHaveTariff() {
      if (!this.tariff) {
        return false;
      }

      return this.requiredTariffs.includes(this.tariff.code);
    },
  },

  methods: {
    toTariffs() {
      this.$router.push({ name: "Tariffs" });
    },
  },
};
</script>

<template>
  <section class="conditions-of-entry">
    <h3>Условия вступления</h3>

    <div class="conditions-of-entry__base-card tariff">
      <div v-if="accHaveTariff" class="tariff_done">
        <div class="contribution__icon">
          <span class="icon-sparkles" />
        </div>

        <h5>
          Необходимый тариф <br />
          у вас уже есть
        </h5>
      </div>

      <div v-else class="tariff_list">
        <h5>Необходимые тарифы</h5>
        <p>Вступите уже сейчас, купив тариф</p>

        <div class="tariff__cards">
          <div
            v-for="tariff in tariffs"
            :key="tariff.name"
            class="tariff__card"
          >
            <span class="tariff__icon" :class="tariff.icon" />
            <p>{{ tariff.name }}</p>
          </div>
        </div>

        <MainButton
          title="Купить тариф"
          color="outlined-grey"
          padding="8px 24px"
          @click="toTariffs"
        />
      </div>
    </div>

    <div class="conditions-of-entry__base-card contribution">
      <div class="contribution__icon">
        <span class="icon-money-fill" />
      </div>

      <div class="contribution__text">
        <h5>Вступительный взнос</h5>

        <p>
          5% от стоимости квартиры <br />
          или 150 000 RC минимально
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.conditions-of-entry {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  border-radius: 20px;
  background: $light-primary;

  h3 {
    margin-bottom: 24px;
    @include title-3;
    color: $dark-primary;
  }

  &__base-card {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background: $light-second;
  }
}

.tariff {
  margin-bottom: 16px;
  min-height: 90px;

  &_list {
    display: grid;
    grid-template-columns: auto;
    width: 100%;

    h5 {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: 4px;
    }

    p {
      @include text-2;
      color: $dark-third;
      margin-bottom: 16px;
    }
  }

  &__cards {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }

  &__card {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: $light-primary;

    span {
      min-width: 16px;
      height: 16px;
      background: $dark-primary;
    }

    p {
      @include caption-1;
      color: $dark-primary;
      margin-bottom: 0;
    }
  }

  .main-button {
    width: 100%;
  }

  &_done {
    display: flex;
    align-items: center;
    gap: 16px;

    h5 {
      @include body-1-bold;
    }
  }
}

.contribution {
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 4px;

  &__icon {
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light-primary;
    border-radius: 100%;
    grid-row: -1/1;
    grid-column: 1/1;

    span {
      min-width: 20px;
      height: 20px;
      background: $dark-primary;
    }
  }

  h5 {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 4px;
  }

  p {
    @include text-2;
    color: $dark-third;
  }
}

@media (min-width: 576px) {
  .conditions-of-entry {
    padding: 32px 48px;
  }

  .tariff {
    &_list {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto auto;
      align-items: center;
      column-gap: 16px;

      .main-button {
        grid-column: 2/2;
        grid-row: -1/1;
        width: fit-content !important;
        height: fit-content !important;
      }
    }

    &__cards {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1024px) {
  .conditions-of-entry {
    padding: 32px;

    h3 {
      margin-bottom: 32px;
    }

    &__base-card {
      padding: 24px;
    }
  }

  .tariff {
    margin-bottom: 24px;
  }

  .contribution {
    column-gap: 24px;

    &__icon {
      width: 48px;
      height: 48px;

      span {
        min-width: 24px;
        height: 24px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .conditions-of-entry {
    padding: 48px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    column-gap: 24px;
    row-gap: 32px;
    max-width: 820px;

    h3 {
      grid-column: -1/1;
      margin-bottom: 0;
    }
  }

  .tariff {
    margin-bottom: 0;

    &_list {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;
    }

    &__cards {
      margin-bottom: 16px;
    }

    .main-button {
      width: 100% !important;
      grid-column: auto;
      grid-row: auto;
    }

    &_done {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }

  .contribution {
    flex-direction: column;
    align-items: normal;
    gap: 24px;
  }
}
</style>
