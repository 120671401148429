<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "MarketingBlock",
  components: { MainButton },

  data() {
    return {
      link: "",
    };
  },

  methods: {
    openVideo() {
      this.$router.push({ query: { video: "show" } });
      document.getElementById("toRcHouse").clearStates();
    },
  },

  mounted() {
    this.link = `${process.env.VUE_APP_RC_HOUSE_URL}`;
  },
};
</script>

<template>
  <section class="marketing-block">
    <div class="marketing-block__counter counter">
      <div class="counter__icon">
        <span>12</span>
      </div>

      <p>Квартир</p>

      <p>Выдано с 2021 года</p>
    </div>

    <div class="marketing-block__main-info">
      <p>Приобретайте квартиру в рассрочку с минимальным взносом!</p>

      <ul>
        <li>Подача заявки онлайн</li>
        <li>Достаточно только паспорта</li>
        <li>Срок от 2 до 12,5 лет</li>
        <li>Сопровождение</li>
      </ul>

      <img src="../../assets/images/rc house.svg" alt="rc house" />
    </div>

    <div class="marketing-block__buttons">
      <a class="link-like-button" :href="link" target="_blank">
        Перейти на сайт RC HOUSE
      </a>

      <MainButton
        title="Смотреть видеоролик"
        color="outlined-grey"
        padding="12px 24px"
        @click="openVideo"
      >
        <template #iconLeft>
          <span class="icon-play" />
        </template>
      </MainButton>
    </div>

    <img src="../../assets/images/rc house.svg" alt="rc house" />

    <div
      v-if="$route.query.video === 'show'"
      class="video-overlay"
      @click="$router.replace({ name: $route.name })"
    >
      <iframe
        src="https://www.youtube.com/embed/BMAPjH5JLw8?si=jei5SOpSV6QrjxrA;controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.marketing-block {
  display: flex;
  padding: 0 16px 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: $light-primary;
  border-radius: 0 0 20px 20px;

  img {
    display: none;
    max-height: 220px;
    width: auto;
  }

  &__main-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      @include body-1;
      margin-bottom: 16px;
      color: $dark-third;
    }

    ul {
      margin: 0;
      padding: 0;
      @include body-1;
      list-style: none;
      color: $dark-third;
    }

    li:not(:last-of-type) {
      margin-bottom: 6px;
    }

    li:before {
      content: "•";
      padding-right: 6px;
      font-size: 14px;
      color: $dark-primary;
      font-weight: bold;
    }

    img {
      display: block;
      margin-left: auto;
      max-width: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .main-button {
    width: 100%;
    align-items: center;
  }

  .icon-play {
    width: 20px;
    height: 20px;
    background: $dark-primary;
    margin-right: 8px;
  }
}

.counter {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: auto auto;
  column-gap: 16px;
  row-gap: 4px;
  padding: 16px;
  border-radius: 12px;
  background: $light-second;

  &__icon {
    @include body-1-bold;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light-primary;
    border-radius: 100%;
    grid-row: -1/1;
    grid-column: 1/1;
  }

  p {
    &:first-of-type {
      grid-column: 2/2;
      grid-row: 1/1;
      @include body-1-bold;
    }

    &:last-of-type {
      grid-column: 2/2;
      grid-row: 2/2;
      @include text-2;
    }
  }

  .icon-house {
    width: 20px;
    height: 20px;
    background: $dark-primary;
  }
}

.video-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(28, 28, 31, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  > iframe {
    width: 800px;
    height: 470px;

    @media (max-width: 1023px) {
      width: 560px;
      height: 320px;
    }
  }
}

@media (min-width: 576px) {
  .marketing-block {
    padding: 24px 48px 32px 48px;

    &__main-info img {
      max-width: 343px;
    }
  }
}

@media (min-width: 1024px) {
  .marketing-block {
    padding: 48px;
    gap: 32px;
    border-radius: 20px;
  }

  .counter {
    padding: 24px;
    column-gap: 24px;
  }
}

@media (min-width: 1440px) {
  .marketing-block {
    padding: 48px;
    gap: 32px;
    border-radius: 20px;
    max-width: 820px;
    display: grid;
    grid-template-columns: 365px 315px;
    grid-template-rows: auto auto auto;
    align-items: center;
    column-gap: 48px;
    row-gap: 32px;

    &__main-info {
      grid-column: 1/1;
      grid-row: 2/2;

      img {
        display: none !important;
      }
    }

    &__buttons {
      grid-column: 1/1;
      grid-row: 3/3;
    }

    img {
      display: block;
      grid-column: 2/2;
      grid-row: -1/1;
    }
  }

  .counter {
    padding: 24px;
    column-gap: 24px;
    grid-column: 1/1;
    grid-row: 1/1;
  }
}
</style>
