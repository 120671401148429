<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "Calculator",
  components: { MainButton },
};
</script>

<template>
  <section class="calculator">
    <img src="../../assets/images/rchouse-calculator.svg" alt="calculator" />

    <div>
      <h3>Рассчитайте лучшие условия в калькуляторе</h3>

      <p>
        Калькулятор поможет рассчитать ежемесячные платежи и конечную стоимость
        квартиры
      </p>
    </div>

    <MainButton
      title="Перейти к калькулятору"
      color="gold"
      padding="12px 24px"
    />
  </section>
</template>

<style lang="scss" scoped>
.calculator {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 20px;
  background: $services-online;

  img {
    width: 72px;
    height: 72px;
  }

  h3 {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 8px;
  }

  p {
    @include body-1;
    color: $dark-third;
  }

  .main-button {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .calculator {
    grid-template-columns: 72px 1fr;
    grid-template-rows: auto auto;
    padding: 32px 48px;

    img {
      grid-row: 1/1;
      grid-column: 1/1;
    }

    .main-button {
      grid-column: 2/2;
      width: fit-content !important;
    }
  }
}

@media (min-width: 1024px) {
  .calculator {
    padding: 32px;
  }
}

@media (min-width: 1440px) {
  .calculator {
    padding: 48px;
    max-width: 820px;
  }
}
</style>
